import req from '../api/https.js'

export const macService ={
    macwrite,
    macupdate,
    macfind,
    macfindall,
    macDel,
    findrole,
    macCopy,
    macWriteTmp,
    macUpdateTmp,
    macDelTmp,
    getTmpActData,
    macfindgetOrg,
    checkscore,
}


function macwrite(dev){
    return req('post','mac/macwrite',dev).then(rs=>{        
            return rs.data
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function macWriteTmp(dev){
    return req('post','mac/macWriteTmp',dev).then(rs=>{        
            return rs.data
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function macUpdateTmp(dev){
    return req('put','mac/macUpdateTmp',dev).then(rs=>{        
            return rs.data
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function macupdate(dev){
    return req('put','mac/macupdate',dev).then(rs=>{
        return rs
    }).catch(error =>{
        return Promise.reject(error);
    })    
}


function macfind(id){
    return req('get', 'mac/macfind/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function checkscore(id){
    return req('get', 'mac/checkscore/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}


function macfindgetOrg(id){
    return req('get', 'mac/macfindgetOrg/'+id).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function macfindall(id,uid){
    return req('get', 'mac/mactall/'+id+'/'+uid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}


function macDel(obj){
    return req('delete', 'mac/macdel/'+obj.id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function macCopy(obj){
    return req('post','mac/macCopy',obj).then(rs=>{        
            return rs.data
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function findrole(obj){
    return req('get', '/mac/roles/'+obj.states+'/'+obj.uid+'/'+obj.firstDate+'/'+obj.lastDate).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function macDelTmp(obj){
    return req('delete', 'mac/macDelTmp/'+obj.id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function getTmpActData(id){
    return req('get', 'mac/getTmpActData/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}



